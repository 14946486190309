.TableContainer {
    margin-top: 350px;
    margin-left: 70px;
    background-color: white;
    width: 1100px;
    position: absolute;
}

.TableContainer  td {
    text-align: center;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    
    

}



.thead  th{
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    color: white;
    width: 190px;
    margin-left: 10px;
    margin-right: 10px;



}

.BgDark {
    background-color: rgb(1, 1, 58);
}

.Bgligth{
    background-color:#1976d2;
    box-shadow: 0px 0px 1px white;
    color: white;
    

}

.BgWhiteDark {
    background-color: #F4F4F4;
}


@media (max-width: 768px) {

    .TableContainer {   
        display: none;
    }
    
  
}