.LogoContainer {
    margin-top: -17px;
   margin-left: 50px;
  }
  
  .LogoContainer img {
    width: 50px;
  }

  .LogoContainer a {
   text-decoration: none;
  }

  .x {
    color: #1976d2;
    font-size: 20px;
    margin-left: 10px;
  }

  .power {
    font-size: 20px;
    font-weight: 600;
    color: black;

  }

  .trade {
    font-size: 20px;
    color: black;
    font-weight: 400;
  }

  @media only screen and (max-width: 768px) {
    .LogoContainer {
      margin-top: -20px;
   
     margin-left: 5px;
    }
    
    
    
    .LogoContainer img {
      width: 30px;
      margin-top: -10px;
    }
    .LogoContainer a {
    display: flex;
    gap: 4px;

  }
  .x {
    font-size: 12px;
  }
  .power {
    font-size: 12px;
  }
  .trade {
    font-size: 12px;
  }


    
  }