.Advantages {
    margin-top: 100px;
    .Text {
        margin-left: 70px;
    }
    .Text h6 {
        font-family: "Poppins", Sans-serif;
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        color: #1976d2;
    }
    .Text h3 {
        color: #1F2649;
        font-family: "Poppins", Sans-serif;
        font-size: 30px;
        font-weight: 800;
        line-height: 24px;
        margin-top: 10px;
    }

  
    .IconsContainer {
        display: flex;
        gap: 20px;
        justify-content: center;
        text-align: center;
        margin-right: 40px;
        margin-top: 40px;
    }

    .IconsContainer p {
        font-family: "Poppins", Sans-serif;
        font-weight: 800;
        font-size: 12px;
        margin-top: 20px;

    }
    
.marca {
    color: #1976d2;

}

.marca1 {
    color:black
}
.marca2 {
    font-weight: 500;
    color: black;
}

}

@media screen and (max-width: 768px) {
    .Advantages {

        .Text {
            margin-left: 20px;
        }

        .Text h6 {
            font-size: 25px;
        }

        .Text h3 {
            font-size: 25px;

        }

        .IconsContainer {
            display: grid;
        }

    }
}