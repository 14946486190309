.BgContainer {
    background-image: url('../../../../image/Bg-Safety.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50vh;
    margin-top: 70px;
    .TextBg {
        position: absolute;
        margin-left: 100px;
        margin-top: 50px;
        
    }
    
    .TextBg  h1 {
        color: white;
        font-family: "Poppins", Sans-serif;
        font-size: 48px;
        font-weight: 800;
        margin-top: 40px;
    }
    
    .TextBg  h3 {
        color: #1976d2;  
        font-family: "Poppins", Sans-serif;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 24px;
        margin-top: 20px;
    }
   
   

}