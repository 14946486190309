.DepositsContainer {
  margin-top: 70px;
  margin-left: 350px;
}

.PaymentsAndTransfer {
  box-shadow: 0px 0px 1px;
  margin-left: 8%;
  border-radius: 8px;
  margin-top: 60px;
  display: flex;
  width: 760px;
  
}

.PaymentsAndTransfer button {
  margin-left: 5px;
  border: none;
  background-color: white;
  outline: none;
  margin-right: 5px;
  margin-top: 4px;
  display: grid;
  margin-bottom: 4px;
  font-size: 13px;
  width: 250px;
  height: 55px;
  border-radius: 8px;
  opacity: 0.7;
  color: #6585a4;
  font-weight: 500;

}

.PaymentsAndTransfer button:focus {
  background-color: #1976d2;
  color: white;
  opacity: 2;
}

.icons {
  margin-left: 50%;
}

.deposites {
  margin-top: 50px;
  margin-left: 8%;
}
.EnterDeposit input {
  width: 650px;
  border-radius: 8px;
  height: 42px;
  border: none;
  box-shadow: 0px 0px 1px;
  outline: none;
  margin-bottom: 30px;
}

.EnterDeposit label {
  box-shadow: 0px 0px 1px;
  height: 42px;
  border-radius: 8px;
  margin-top: -10px;
}


  w

.EnterDeposit input::placeholder {
}

.alternative {
  margin-top: 50px;
  margin-left: 8%;
}

.alternative h2 {
  width: 700px;
}

.Transfers {
  margin-top: 50px;
  margin-left: 8%;
}

.Gap button {
    border-radius: 15px;
    border: none;
    background-color: white;
}
.Gap button:hover {
   background-color: #1976d2;
}
.Gap p {
  margin-top: -8px;
}
.Gap b {
  margin-top: -10px;
}
.nameBank {
  display: flex;
  gap: 70px;
}

.nameBank p {
  color: #6585a4;
}

.BankAddress {
  display: flex;
  gap: 70px;
}
.BankAddress p {
  color: #6585a4;
}

.buttonIcons {
   color:#6585a4 ;

    
}

.Beneficiary {
  display: flex;
  gap: 70px;
}

.Beneficiary p {
  color: #6585a4;
}

.BeneficiaryAddress {
  display: flex;
  gap: 70px;
}

.BeneficiaryAddress p {
  color: #6585a4;
}

.Swift {
  display: flex;
  gap: 70px;
}

.Swift p {
  color: #6585a4;
}

.Reference {
  display: flex;
  gap: 70px;
}

.Reference p {
  color: #6585a4;
}

.select {
  display: flex;
  gap: 80px;
  margin-left: 15px;
}

.select p {
  font-size: 14px;
}

.btn {
  color: #fff;
  background-color: #1976d2;
  border: none;
  border-radius: 8px;
  width: 450px;
  height: 45px;
  margin-top: 15px;
}

.EurAndLt {
  display: flex;
  gap: 80px;
  box-shadow: 0px 0px 1px;
  width: 760px;
  border-radius: 8px;
  
  
}

.EurAndLt p {
  margin-left: 15px;
  font-size: 12px;
  margin-top: 12px;
  font-weight: 500;
}

.EurAndLt:hover {
   background-color: rgb(228, 228, 228);
}

.data-bank {
  display: flex;
  gap: 3em;
}

.data-container {
  display: grid;
  gap: 1em;
}


.container-deposit {
  margin-top: 2em;
  width: 50%;
}

@media screen and (max-width: 767px) {
  .container-deposit {
    width: 100%;
  }
  .title-banca {
    font-size: 15px;
  }
  .data-bank {
    font-size: 10px;

  }
}