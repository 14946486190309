.AccordionContainer {
    margin-left: 100px;
    display: flex;
    margin-top: 80px;
    margin-bottom: 120px;

    .BoxContainer h2 {
        color: #1F2649;
        font-family: "Poppins", Sans-serif;
        font-size: 24px;
        font-weight: 800;
        line-height: 24px;
    }

    .BoxContainer strong {
        font-family: "Poppins", Sans-serif;
        font-size: 17px;
        font-weight: 600;
    }

    .BoxContainer p {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 17px;
        font-family: "Poppins", Sans-serif;

    }

    .RequestSupport {
        margin-left: 50px;
    }



    .RequestSupport h6 {
        font-family: "Poppins", Sans-serif;
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        color: #1976d2;
    }

    .RequestSupport h3 {
        color: #1F2649;
        font-family: "Poppins", Sans-serif;
        font-size: 30px;
        font-weight: 800;
        line-height: 24px;
        margin-top: 10px;
    }


    .RequestSupport p {
        width: 280px;
        font-size: 15px;
        margin-top: 20px;
    }

    .SubTittles {
        margin-top: 20px;
    }


    .SubTittles p {
        font-size: 15px;
        margin-top: 0px;
        font-family: sans-serif;

    }

    .BtnContainer {
        margin-top: 20px;
    }


    .BtnContainer button {
        font-family: "Poppins", Sans-serif;
        text-transform: uppercase;
        border-radius: 25px 25px 25px 25px;
        background-color: #1976d2;
        color: rgb(1, 1, 58) ;
        border: none;
        width: 130px;
        height: 30px;
    }

    .BtnContainer button span {
        flex-grow: 1;
        order: 10;
        font-weight: 500;
        display: inline-block;
        box-sizing: border-box;
        font-size: 13px;
    }

    .BoxContainer p a {
        color: #1976d2;
    }

}

@media (max-width: 768px) {
    .AccordionContainer {
      margin-left: 20px;


      .BoxContainer strong{
        width: 300px;

      }

      .BoxContainer p {
        width: 300px;
        margin-left: 32px;

      }
      .RequestSupport {
       display: none;
      }

      .BoxContainer input {
        width: 10px;
      }
    }
  }