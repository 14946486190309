.TakeContainer {
    background-color: #ECF2F7;
    margin-top: 110px;
    height: 180vh;
}

.BoxContainer {
    padding: 80px 50px 50px 95px;

}

.Text h6 {
    font-family: "Poppins", Sans-serif;
    font-size: 19px;
    font-weight: 800;
    line-height: 24px;
    color: #1976d2;


}

.Text h3 {
    color: #1F2649;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 10px;
}

.Text p {
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 25px;
}

.Steps {
    margin-top: 50px;
    display: flex;
    gap: 20px;

}

.StepsCardsTrading {
    background-color: white;
    box-shadow: 0px 0px 0px,
        0px 0px 10px rgb(193, 191, 191);
    width: 250px;
    height: 270px;
    display: grid;
    border-radius: 15px;
    justify-content: center;
}

.StepsCardsTrading h6 {
    color: #313131;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: -40px;
    margin-left: 30px;
    text-align: center;
}

.StepsCardsTrading img {
    margin-left: 80px;
    margin-top: 50px;
}

.StepsCards {
    background-color: white;
    box-shadow: 0px 0px 0px,
        0px 0px 10px rgb(193, 191, 191);
    width: 250px;
    height: 270px;
    justify-content: center;
    display: grid;
    border-radius: 15px;
}


.StepsCards h6 {
    color: #313131;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: -40px;
    align-items: center;
}

.StepsCards img {
    margin-left: 40px;
    margin-top: 50px;
}


.linea {
    font-weight: bold;
    margin-top: 100px;
}

@media (max-width: 768px) {

    .TakeContainer {
        margin-left: -50px;
        height: 192vh;

        .ImgContainer img {
            width: 300px;
        }

        .Steps {
            display: flex;
            flex-direction: column;

        }

        .StepsCards {
            width: 300px;

        }

        .StepsCardsTrading {
            width: 300px;
        }



        .StepsCardsTrading img {
            margin-left: 120px;

        }

        .StepsCardsTrading h6 {
            margin-left: 10px;

        }

        .linea {
            display: none;
        }


    }


}