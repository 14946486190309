.sidebar {
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: sticky;
    background-image: linear-gradient(#015C92, #2D83B5, #89CCF6);
}
.sidebar-logo-container {
    display: 'flex';
    align-items: center;
}
.sidebar-logo-container img {
    width: 50%;
    padding: 2em;
}
.sidebar-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.sidebar-items {
    height: 100%;
    width: 100%;
}
.sidebar-item {
    padding: 1.5em;
    padding-left: 2em;
    display: flex;
    color: #FFFFFF;
    list-style: none;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.sidebar-item:hover {
    background-color: #004B77;
}
.sidebar-item-active {
    padding: 1.5em;
    padding-left: 2em;
    display: flex;
    color: #FFFFFF;
    list-style: none;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    background-color: #004B77;
    cursor: pointer;
}
.sidebar-item-icon {
    width: 20px;
}
.sidebar-item-label {
    font-size: small;
    font-weight: 600;
    margin-left: 15px;
}
.sidebar-color {
    display: grid;
    gap: 20px;
    margin-top: 40px;
    margin-left: 30px;

}
.sidebar-color a {
    color: white;
    text-decoration: none;
    font-size: 13px;
    display: flex;
    gap: 15px;

}



.icons-panel {
    width:  50px;
}
/* Footer */
.sidebar-footer {
    padding: 1.5em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.decoration {
    text-decoration: none;
    color: black;
    /* Agrega estilos adicionales según sea necesario */
  }

  .img-panel {
    display: flex;
    justify-content: center;
  }

  .img-panel img {
    width: 5em;
  
  }


  .navbar-menu {
    position: absolute;
    margin-left: 95%;
    margin-top: 0.5%;
  }


  
@media (max-width: 768px) {
    .navbar-menu {
        position: absolute;
        margin-left: 80%;
        margin-top: 2%;
      }
      
}