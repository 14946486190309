.homeContainer {
    margin-top: 20px;



    .image {
        width: 100%;
    }

    .dataPublicity {
        margin-top: -450px;
        margin-left: 50px;
    }

    .x {
        color: #1976d2;
    }

    .power {
        color: #000;
    }

    .trade {
        font-weight: 300;
    }

    .dataPublicity h1 {
        color: white;

        font-family: sans-serif;
        font-weight: 600;
        margin-top: -12px;
    }

    .dataPublicity p {
        width: 300px;
        text-align: center;
        font-size: 16px;
        color: white;
        font-family: "Poppins", Sans-serif;
        font-weight: 500;
        margin-top: -5px;

    }

    .dataPublicity button {
        font-family: "Poppins", Sans-serif;
        font-size: 20px;
        font-weight: 700;
        color: rgb(1, 1, 58);
        background-color: transparent;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #1976d2;
        border-radius: 25px 25px 25px 25px;
        padding: 10px 30px 10px 30px;
    }

    .dataPublicity button:hover {
        background-color: #1976d2;
        color: white;
        box-shadow: 0px 0px 4px black;
        border-color: rgb(1, 1, 58);
    }

    .cards {
        display: flex;
        margin-right: 50px;
        margin-top: -20px;
        margin-left: 40px;
    }

    .cardSuperfast {
        margin-top: 150px;
        margin-left: 40px;
        box-shadow: 0px 0px 4px;
        display: grid;
        border-radius: 15px;
        padding: 25px 25px 25px 25px;
        background-color: white;
    }


    .cardSuperfast strong {
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        margin-top: 10px;
    }



    .cardSuperfast p {
        text-align: center;
        height: 135px;
        margin-top: 20px;
        margin-bottom: 1rem;
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 11px;
        font-weight: 500;
    }

    .cardTech {
        margin-top: 150px;
        margin-left: 40px;
        box-shadow: 0px 0px 4px;
        display: grid;
        border-radius: 15px;
        padding: 25px 25px 25px 25px;
        background-color: white;

    }

    .cardTech strong {
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        margin-top: 10px;
    }

    .cardTech p {
        text-align: center;
        height: 135px;
        margin-top: 20px;
        margin-bottom: 1rem;
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 11px;
        font-weight: 500;
    }

    .cardTight {
        margin-top: 150px;
        margin-left: 40px;
        box-shadow: 0px 0px 4px;
        display: grid;
        border-radius: 15px;
        padding: 25px 25px 25px 25px;
        background-color: white;

    }

    .cardTight strong {
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        margin-top: 10px;
    }

    .cardTight p {
        text-align: center;
        height: 135px;
        margin-top: 20px;
        margin-bottom: 1rem;
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 11px;
        font-weight: 500;
    }

    .cardUltimate {
        margin-top: 150px;
        margin-left: 40px;
        box-shadow: 0px 0px 4px;
        display: grid;
        border-radius: 15px;
        padding: 25px 25px 25px 25px;
        background-color: white;

    }

    .cardUltimate strong {
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        margin-top: 10px;
    }

    .cardUltimate p {
        text-align: center;
        height: 135px;
        margin-top: 20px;
        margin-bottom: 1rem;
        color: #313131;
        font-family: "Poppins", Sans-serif;
        font-size: 11px;
        font-weight: 500;
    }

    .cardSuperfast img {
        margin-left: 57px;
    }

    .cardTight img {
        margin-left: 75px;
    }

    .cardTech img {
        margin-left: 30px;
    }

    .cardUltimate img {
        margin-left: 110px;
    }

}



@media (max-width: 768px) {
    .homeContainer {
        display: flex;
        flex-direction: column;

        .image {
            height: 30vh;

        }

        .dataPublicity {
            display: flex;
            flex-direction: column;
            margin-top: -160px;
            margin-left: 10px;

        }

        .dataPublicity h1 {
            font-size: 20px;
            text-align: center;

        }

        .dataPublicity p {
            font-size: 16px;
            margin-left: 40px;
            font-size: 11px;
        }

        .dataPublicity a button {
            font-size: 12px;
            background-color: #1976d2;
            color: white;
            margin-left: 80px;
        }

        .cards {
            display: flex;
            flex-direction: column;
            margin-top: -80px;

        }

        .cardSuperfast {

            width: 100%;
            margin-left: 5px;
        }

        .cardSuperfast img {
            margin-left: 95px;
        }

        .cardTight {
            margin-top: 30px;

            width: 100%;
            margin-left: 5px;
        }

        .cardTight img {
            margin-left: 95px;
        }

        .cardTech {
            margin-top: 30px;

            width: 100%;
            margin-left: 5px;
        }

        .cardTech img {
            margin-left: 95px;
        }

        .cardUltimate {
            margin-top: 30px;
            width: 100%;
            margin-left: 5px;
        }

        .cardUltimate img {
            margin-left: 95px;
        }
    }
}

.loading_container {
    position: relative;
}

.loading_container img {
    display: block;
    width: 180px;
    margin-top: 20vh;
    margin-left: 40%;
}

.loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
}

 