.form {
    display: grid;
    gap: 2em;
}

.form_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 16px;
}