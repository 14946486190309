.platformsContainer {
    margin-top: 110px;
    margin-left: 50px;
    
    .tradingPlatforms {
        h6 {
            font-size: 18px;
            font-weight: 800;
            color: #1976d2;
        }
        
        h3 {
            color: #1F2649;
            font-family: "Poppins", Sans-serif;
            font-size: 30px;
            font-weight: 800;
            line-height: 24px;
            margin-top: 15px;
        }
        
        p {
            margin-top: 15px;
            margin-bottom: 1rem;
            width: 450px;
        }
        
        img {
            float: right;
            width: 650px;
        }
    }
    
    .AccordionContainer {
        margin-top: 30px;
        margin-left: 10px;
    }
    
    .accordion {
        width: 500px;
        border: none;
        box-shadow: 0px 1px #1976d2;
        margin-bottom: 1px;
    }
    
    .titleAccordion {
        font-family: Sans-serif;
        font-size: 21px;
        font-weight: 800;
        line-height: 21px;
        margin-left: 0px;
    }
    
    .btnContainer {
        margin-top: 20px;
    }
    
    .btnContainer button {
        font-family: "Poppins", Sans-serif;
        font-size: 17px;
        font-weight: 600;
        border-radius: 25px;
        padding: 15px 30px;
        background-color: rgb(1, 1, 58);
        color: white;
        border: none;
    }
    
    .btnContainer button:hover {
        background-color: #1976d2;
        color: white;
        box-shadow: 0px 0px 4px black;
        border-color: rgb(1, 1, 58);
    }
    
    /* Media Query para pantallas de 768px o menos */
    @media (max-width: 768px) {
        margin-top: 180px;
        margin-left: 20px;
        
        .tradingPlatforms {
            h3 {
                font-size: 24px;
            }
            
            p {
                width: 100%;
            }
            
            img {
                width: 100%;
            }
        }
        
        .AccordionContainer {
            margin-top: 20px;
            margin-left: 0;
        }
        
        .accordion {
            width: 100%;
        }
        
        .titleAccordion {
            margin-left: 10px;
        }
        
        .btnContainer {
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }
        
        .btnContainer button {
            padding: 12px 25px;
        }
    }

}
