.panelContainer {
  margin: auto;
}
.btn_image {
  background-image: url('../../image/Trading-1.jpg');
  background-color: rgba(0, 0, 0, 0.5); /* Color negro con una opacidad del 50% */
  background-blend-mode: multiply; /* Mezcla el color de fondo con la imagen */
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 25em;
  max-width: 100%;
  margin-bottom: 2em;
}

.table {
  max-height: 400px;
  overflow-y:  auto;
}

.position_closed {
  width: 100%;

}
.btn_text {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding-top: 8em;
}
.card_moneys {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
}
.containerButton {
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  gap: 1em;
}
.notTrading {
  padding-top: 5em;
  text-align: center;
  margin: auto;
}

.positionClosed {
  display: flex;
  width: 100%;
}

.positionClosed h4 {
padding: 1em;
}
.table_body {
  max-height: 300px; /* Ajusta esta altura según sea necesario */
  overflow-y: auto;
}




@media (max-width: 768px) {
  
  .btn_image {
 
    height: 15em;

  }
  .btn_text {
    padding-top: 10%;
  }

    .card_moneys {
      display: flex;
      justify-content: flex-start;
      flex-direction:column;
      
    }
  .containerButton {
    display: flex;
    justify-content: flex-start;
    flex-direction:column;

  }

  .notTrading {
    padding-top: 5em;
    text-align: center;
  }
  .table_container {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
  }

 .table {
    max-height: 400px;
    max-width: 300px;
    overflow:  auto;
  } 
}