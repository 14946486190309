.OpenAccountContainer {
    background: linear-gradient(135deg, rgba(3, 96, 9, 0.547) 0%, rgba(81, 224, 81, 0.543) 100%),
        url("../../../image/bg-openAccount.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    margin-top: 250px;
}

.infoAccount {
    position: absolute;
    margin-top: 120px;
    margin-left: 50px;
    color: white;

    span {
        color: #FFFFFF;
        font-family: "Poppins", Sans-serif;
        font-size: 18px;
        font-weight: bold;
    }

    h2 {
        color: #FFFFFF;
        font-family: "Poppins", Sans-serif;
        font-size: 30px;
        font-weight: 800;
    }
}

.typeRegister {
    position: absolute;
    display: flex;
    color: white;
    margin-top: 250px;
    margin-left: 70px;
    gap: 30px;

    h2 {
        font-size: 64px;
        font-weight: 800;
    }

    .descriptionRegister {
        margin-top: -25px;

        span {
            font-size: 22px;
            color: #FFFFFF;
            font-weight: 600;
            line-height: 86px;
            margin-top: -10px;
        }

        p {
            color: #FFFFFF;
            font-family: "Poppins", Sans-serif;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 16px;
            width: 190px;
            margin-top: -20px;
        }
    }
}

/* Media Query para pantallas de 768px o menos */
@media (max-width: 768px) {
    .OpenAccountContainer {
        margin-top: 100px;
        display: flex;
        justify-content: center;
        .infoAccount {
            margin-top: 60px;
            margin-left: -120px;

            h2 {
                font-size: 24px;
            }
        }

        .typeRegister {
            margin-top: 150px;
            margin-left: 20px;
            display: grid;

            h2 {
                font-size: 36px;
            }

            .descriptionRegister {
                margin-top: -140px;
                margin-left: 60px;

                span {
                    font-size: 18px;
                    line-height: 50px;
                }

                p {
                    font-size: 10px;
                    line-height: 14px;
                    width: 120px;
                    margin-top: 5px;
                }
            }
        }

       
    }

}