.Textcontainer {
    margin-left: 6%;
    h3 {
        font-family: sans-serif;
        font-size: 30px;
        font-weight: 800;
    }
    p {
        margin-top: 15px;
        box-sizing: border-box;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin-right: 45px;
    }

    img {
        margin-top: 30px;
    }
}