.LegalContainer {
    margin-top: 30px;
    margin-left: 70px;
    margin-bottom: 100px;
    h3 {
        color: #1F2649;
        font-family: "Poppins", Sans-serif;
        font-size: 40px;
        font-weight: 800;
        line-height: 24px;
    }
    .information {       
        margin-top: 110px;
    }
    
    .information p {
        font-family: sans-serif;
        font-size: 17px;
        margin-top: -40px;
    }
    .explicity {
        margin-top: 60px;
    }

    .explicity  p {
        font-family: sans-serif;
        font-size: 17px;
        margin-top: -40px;
    }

    .explicity p a {
        text-decoration: none;
        color: #1976d2;
    }
}
.loading_container {
    position: relative;
  }
  
  .loading_container img {
    display: block;
    width: 180px;
    margin-top: 20vh;
    margin-left: 40%;
  }
  
  .loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

@media (max-width: 768px) {
    .LegalContainer {
        width: 330px;
        margin-left: 30px;

        h3 {
            width: 750px;
            font-size: 30px;
            
        }

      
 
    }
    }