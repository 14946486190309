.EzinvestContainer {
    background-image: url('../../../image/AbaoutUS-bg.jpg');
    background-position: bottom center;
    height: 50vh;
    margin-top: 20px;

    .TitlesEzivenvest {
        text-align: center;
    }

    .TitlesEzivenvest h1 {
        color: #FFFFFF;
        font-family: "Poppins", Sans-serif;
        font-size: 48px;
        font-weight: 800;
        text-transform: uppercase;
    }

    .TitlesEzivenvest h2 {
        color: #1976d2;
        font-family: "Poppins", Sans-serif;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 24px;
        margin-top: 20px;
    }
}

.TextImage {
    display: flex;
    gap: 50px;
    margin-top: 80px;
    margin-left: 65px;
}

.Text h6 {
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    color: #1976d2;
}

.Text h3 {
    color: #1F2649;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 10px;
}

.Text p {
    font-family: "Poppins", Sans-serif;
    width: 480px;
    margin-top: 20px;

}

.ImgContainer {
    margin-bottom: 50px;
}

.ImgContainer img {
    border-radius: 10px;
}

.BtnContainer {
    margin-top: -130px;
    margin-bottom: 50px;
    margin-left: 65px;
}

.BtnContainer button {
    font-family: "Poppins", Sans-serif;
    font-weight: 600;
    border-radius: 25px 25px 25px 25px;
    background-color: #1F2649;
    color: white;
    border: none;
    height: 50px;
    width: 250px;
}

.BtnContainer button span {
    font-family: "Poppins", Sans-serif;
    font-weight: 600;
    font-size: 14px;

}

.BtnContainer button:hover {
    background-color: #1976d2;
    color: white;
    box-shadow: 0px 0px 4px black;
    border-color: #1F2649;
}

.loading_container {
    position: relative;
  }
  
  .loading_container img {
    display: block;
    width: 180px;
    margin-top: 20vh;
    margin-left: 40%;
  }
  
  .loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }


@media (max-width: 768px) {
    .TextImage {
        display: flex;
        flex-direction: column-reverse;
        margin-left: 20px;
    }

    .ImgContainer img{
        width: 350px;

    }

    .Text {
        margin-top: -60px;
    }

    .Text p {
        width: 330px;
    }

    .BtnContainer {
        margin-top: 10px;
    }
}