.menus {
  margin-top: 40px;
  margin-left: 50px;
  display: flex;
}

.deposito{
  margin-top: -48px;
}

.deposito a button {
  border-radius: 8px;
  background-color: transparent;
  padding: 5px 20px;
  border-color: #1976d2;
  font-size: 11px;
  font-family: sans-serif;
  font-weight: 700;
  color: black;
  text-decoration: none;
  border-width: 1px;
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin-left: 55px;
  margin-top: -7px;
}

.btnLogin a {
  font-size: 11px;
  font-weight: 600;
  text-decoration: none;
  color: black;
}


.btnSignUp a button {
  border-radius: 8px;
  background-color: transparent;
  padding: 6px 35px;
  border-color: #1976d2;
  font-size: 11px;
  font-family: sans-serif;
  font-weight: 700;
  color: #01013a;
  text-decoration: none;
  border-width: 1px;
}

.btnSignUp a button:hover {
  color: white;
  background-color: #1976d2;
}


@media only screen and (max-width: 768px) {

  .btnContainer {
    display: flex;
    margin-top: -36px;
  }

  .btnSignUp a button  {
    border: none;
    width: 1px;
  }

  .btnSignUp a button:hover {
    color: #1976d2;
    background-color: transparent;
  }
  


.btnLogin a {
  margin-left: 0px;
  margin-top: 5px;
  position: absolute;

}



  
}