.ProductsContainer {
    margin-top: 60px;
    margin-left: 70px;
    display: flex;
    gap: 50px;

}
.Text h6 {
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    color: #1976d2;
}

.Text h3 {
    color: #1F2649;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 10px;
}


.Text p  {
    width: 500px;
    font-size: 17px;
    margin-top: 20px;
}
.BtnContainer {
 margin-top: 40px;
}

.BtnContainer button {
    font-family: "Poppins", Sans-serif;
    font-weight: 600;
    border-radius: 25px 25px 25px 25px;
    background-color: #1F2649;
    color: white;
    border: none;
    height: 50px;
    width: 250px;
}

.Image img{
    border-radius: 13px;
}

.BtnContainer button:hover {
    background-color: #1976d2;
    color: white;
    box-shadow: 0px 0px 4px black;
    border-color: #1F2649;
}

.BtnContainer button span {
    font-family: "Poppins", Sans-serif;
    font-weight: 600;
    font-size: 14px;

}

@media (max-width: 768px) {

  .ProductsContainer {
    margin-left: 20px;

    .Text h3 {
        width: 350px;
    }
    .Text p {
        width: 345px;
    }
  }
  
}
