.ImgBG{    
        background-image: url('../../../image//Bg-Safety.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 50vh;
        margin-top: 70px;
    
}

.TextBg {
    margin-left: 100px;
    margin-top: 50px;
    text-align: center;
    
}

.TextBg  h1 {
    color: white;
    font-family: "Poppins", Sans-serif;
    font-size: 48px;
    font-weight: 800;
    margin-top: 40px;
}

.TextBg  h3 {
    color: #1976d2;  
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 24px;
    margin-top: 20px;
}

.loading_container {
    position: relative;
  }
  
  .loading_container img {
    display: block;
    width: 180px;
    margin-top: 20vh;
    margin-left: 40%;
  }
  
  .loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

@media (max-width: 768px) {

    .ImgBG {
        .TextBg  h1 {
            font-size: 25px;
            margin-top: 0;
        }
    }
}