.IdentifyContainer {
margin-bottom: 2em;
    .ImgContainer img {
        width: 50px;
        margin-top: 0px;
        margin-left: -150px;
    }

    .IdentifyBox {
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 1px;
        border-radius: 8px;
        height: 50px;
        width: 320px;


        .textContainer {
            display: grid;
            text-align: center;

        }

        .textContainer span {
            font-size: 10px;
            margin-top: 5px;
        }

        .textContainer strong {
            font-size: 15px;
            margin-left: 20px;
            margin-right: 20px;

        }

        .usd {
            margin-top: -7px;
        }

        .hr {
            width: 0.01rem;
            height: 50px;
            background: rgb(175, 174, 174);
            border-radius: 0.8rem;
            margin-top: -42px;
        }

        .activeContainer {
            display: flex;
            gap: 5px;
        }

        .active {
            width: 8px;
            height: 8px;
            background: #1976d2;
            border-radius: 0.8rem;
            margin-top: 8.5px;
            margin-left: 10px;
        }

    }
}

@media (max-width: 768px) {
    .IdentifyContainer {

    .ImgContainer img {
        width: 50px;
        margin-top: 0px;
        margin-left: -150px;
    }

    .IdentifyBox {
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 1px;
        border-radius: 8px;
        height: 50px;
        width: 320px;


        .textContainer {
            display: grid;
            text-align: center;

        }

        .textContainer span {
            font-size: 10px;
            margin-top: 5px;
        }

        .textContainer strong {
            font-size: 15px;
            margin-left: 20px;
            margin-right: 20px;

        }

        .usd {
            margin-top: -7px;
        }

        .hr {
            width: 0.01rem;
            height: 50px;
            background: rgb(175, 174, 174);
            border-radius: 0.8rem;
            margin-top: -42px;
        }

        .activeContainer {
            display: flex;
            gap: 5px;
        }

        .active {
            width: 8px;
            height: 8px;
            background: #1976d2;
            border-radius: 0.8rem;
            margin-top: 8.5px;
            margin-left: 10px;
        }

    }
}
}