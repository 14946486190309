  .StartTradingContainer {
    background-color: #ECF2F7;
    margin-top: 110px;
    height: 115vh;
  }

  .BoxContainer {
    padding: 80px 50px 50px 70px;

  }

  .StartTradingContainer h6 {
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    color: #1976d2;


  }

  .StartTradingContainer h3 {
    color: #1F2649;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 10px;
  }

  .Cards {
    display: flex;
    gap: 30px;
    margin-top: 70px;
  }

  .Card {
    height: 380px;
    box-shadow: 0px 0px 4px;
    background-color: white;
    border-radius: 8px;
    width: 200px;
  }

  .Card h5 {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: -8px;
    margin-top: 10px;


  }

  .Card p {
    text-align: center;
    margin-left: 12px;
    font-size: 12px;
    margin-top: 30px;
    width: 170px;



  }

  .ImgContainer {
    display: flex;
    justify-content: center;

  }

  .ImgContainer img {
    margin-top: 20px;

  }

  @media (max-width: 768px) {
    .StartTradingContainer {
      height: 310vh;

      .BoxContainer h6 {
        margin-left: -50px;
      }

      .BoxContainer h3 {
        margin-left: -50px;
      }

      .Cards {
        display: flex;
        flex-direction: column;
      }
    }
  }