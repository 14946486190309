.SafetyContainer {
    display: flex;
    margin-left: 110px;
    margin-top: 120px;
    margin-bottom: 80px;
}

.BgImage {
    background-image: url('../../../image//Bg-Safety.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50vh;
    margin-top: 20px;


}

.TextBg {
    margin-left: 100px;
    margin-top: 50px;
    text-align: center;

}

.TextBg h1 {
    color: white;
    font-family: "Poppins", Sans-serif;
    font-size: 48px;
    font-weight: 800;
    margin-top: 40px;
}

.TextBg h3 {
    color: #1976d2;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 24px;
    margin-top: 20px;
}

.Text h6 {
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    color: #1976d2;
}

.Text h3 {
    color: #1F2649;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 10px;
}


.Text p {
    width: 447px;
    font-size: 15px;
    margin-top: 20px;
    font-family: sans-serif;
}

.ImgContainer img {
    width: 550px;
    border-radius: 10px;
    margin-left: 40px;
    margin-top: 125px;
}

.loading_container {
    position: relative;
  }
  
  .loading_container img {
    display: block;
    width: 180px;
    margin-top: 20vh;
    margin-left: 40%;
  }
  
  .loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

@media (max-width: 768px) {

    .SafetyContainer {
        display: flex;
        flex-direction: column-reverse;
        margin-left: 40px;
        margin-top: -50px;
        gap: 30px;

        .ImgContainer {

            margin-left: -40px;
        }

        .ImgContainer img {
            width: 330px;
        }

        .Text p {
            width: 330px;
        }
    }
}