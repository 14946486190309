.ContactContainer {
    margin-top: 110px;
    margin-left: 80px;
    display: flex;
    margin-bottom: 120px;



    .NameEmail {
        display: flex;
        gap: 20px;
        margin-top: 60px;
    }

    .NameEmail input {
        width: 380px;
        height: 40px;
        border-width: 0px 0px 1px 0px;
        border-radius: 0px 0px 0px 0px;
        box-shadow: 0px 1px #1976d2;
    }

    .NameEmail input:focus {
        outline: none;
        box-shadow: 0px 1px #1976d2,
            0px 0px 1px black;
    }

    .Text h6 {
        font-family: "Poppins", Sans-serif;
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        color: #1976d2;
    }

    .Text h3 {
        color: #1F2649;
        font-family: "Poppins", Sans-serif;
        font-size: 30px;
        font-weight: 800;
        line-height: 24px;
        margin-top: 10px;
    }

    .Message {
        margin-top: 50px;
    }


    .Message textarea {
        width: 780px;
        height: 100px;
        border-width: 0px 0px 1px 0px;
        border-radius: 0px 0px 0px 0px;
        box-shadow: 0px 1px #1976d2;

    }

    .Message textarea:focus {
        outline: none;
        box-shadow: 0px 1px #1976d2,
            0px 0px 1px black;
    }

    .CheckBox {
        margin-top: 30px;
        display: flex;
        gap: 10px;
    }

    .CheckBox label a {
        text-decoration: none;
        color: #1976d2;
    }

    .RequestSupport {
        margin-left: 50px;
        display: none;
    }

    .RequestSupport h6 {
        font-family: "Poppins", Sans-serif;
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        color: #1976d2;
    }

    .RequestSupport h3 {
        color: #1F2649;
        font-family: "Poppins", Sans-serif;
        font-size: 30px;
        font-weight: 800;
        line-height: 24px;
        margin-top: 10px;
    }


    .RequestSupport p {
        width: 280px;
        font-size: 15px;
        margin-top: 20px;
    }

    .SubTittles p {
        font-size: 15px;
        margin-top: -2px;
        font-family: sans-serif;

    }

    .BtnContainer {
        margin-top: 40px;
    }

    .BtnContainer button {
        font-family: "Poppins", Sans-serif;
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        border-radius: 4px 4px 4px 4px;
        padding: 13px 30px 13px 30px;
        background-color: #1F2649;
        border: none;
        color: white;
    }

    .BtnContainer button:hover {
        background-color: #1976d2;
        color: white;
        box-shadow: 0px 0px 4px black;
        border-color: rgb(1, 1, 58);
    }
}

@media (max-width: 768px) {
    .ContactContainer {
     display: flex;
     flex-direction: column;

     .NameEmail {
        display: flex;
        flex-direction: column;
        margin-left: -50px;
    }
    
    .NameEmail input {
         width: 330px;

     }

     .Message {
        display: flex;
        flex-direction: column;
        margin-left: -50px;

     }

     .Message textarea {
        width: 330px;

     }

     .Text h6 {
        margin-left: -50px;
     }
     
     .Text h3 {
        margin-left: -50px;
     }
     
     .RequestSupport {
        display: none;
     }
    }
  }