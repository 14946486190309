.AccordionContainer {
    margin-bottom: 130px;
    margin-left: 70px;
      
      
      .BoxContainer h2 {
          color: #1F2649;
          font-family: "Poppins", Sans-serif;
          font-size: 30px;
          font-weight: 800;
          line-height: 24px;
          
      }


      .BoxAccordion {
        margin-top: 50px;
      }
      strong {
        font-size: 18px;
      }
      
      
  }

  @media screen and (max-width: 768px) {
    .AccordionContainer {
      margin-left: 20px;
      margin-top: -50px;
    }
  
    .BoxAccordion p {
      width: 330px;
    }
  
    .BoxAccordion strong {
      width: 330px;
    }
  }