.AccordionContainer h3 {
    color: #1F2649;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 50px;
}

.AccordionContainer {
    margin-left: 80px;
    margin-top: 40px;
    margin-bottom: 70px;
}

.title {
    color: #1F2649;
    font-weight: bold;
}

.list {
    list-style: none;
}

.list li a {
    text-decoration: none;
    color: #1976d2;
}

.Accordion {
    width: 93%;
}
.loading_container {
    position: relative;
  }
  
  .loading_container img {
    display: block;
    width: 180px;
    margin-top: 20vh;
    margin-left: 40%;
  }
  
  .loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }



@media (max-width: 768px) {
    .AccordionContainer {
        width: 360px;
        margin-left: 20px;
    }
}